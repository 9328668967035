import { React, useState } from 'react'
import "./services.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Link } from 'react-router-dom';

import timeicon from "../../assets/images/time-icon.png";
import teamicon from "../../assets/images/team-icon.png";
import focusicon from "../../assets/images/focus-icon.png";
import trusticon from "../../assets/images/trust-icon.png";

import Form from "./Form";
import ClientReview from '../../Components/Reviews/ClientReview';

import Client2 from '../Main-page/Client2';
import Client3 from '../Main-page/Client3';
import { Icon } from '@iconify/react';
import data from '../OurWorks/data';
import data1 from '../OurWorks/data1';
import { Helmet } from 'react-helmet';

const Android = () => {
    const [showMore, setShowMore] = useState(false);


    const [sites, setSites] = useState(data);
    const [apps, setApps] = useState(data1);
  
    const toggleMoreText = () => {
        setShowMore(!showMore);
    };

    return (

        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Android application development Service- Grintech Web Agency</title>
            <meta name="description" content="Android App Development services. We create high-quality, salable, and user-friendly Android applications that drive business success and meet user expectations" />
            <link rel='canonical' href='/our-services-android' />
          </Helmet>


            <Header />

            <section className="dev-passion mt-4 mb-1">
                <div className="container d-none d-lg-block py-3">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="passion">
                                <h1 className='fw-bold'>Android App Development Company</h1>
                                <div className=''>
                                    <p>Build Your Vision with Android Development Service by Grintech. We turn your ideas into fully functional, user-friendly Android apps with cutting-edge technology and design. From concept to launch, we ensure your app stands out and performs seamlessly on all Android devices.</p>

                                    <h2>Android App Development Services Provided by Grintech Web Agency:</h2>
                                    <ul className="p-0">
                                    <li className='mb-2'><b>1. Custom App Development:</b> Creating tailored Android apps designed to meet your specific business needs and objectives.</li>
                                    <li className='mb-2'><b>2. User-Friendly Design:</b> Developing intuitive and engaging user interfaces that enhance the user experience and drive user satisfaction.</li>
                                    <li className='mb-2'><b>3. Native & Cross-Platform Solutions:</b> Building both native Android apps and cross-platform apps using frameworks like Flutter or React Native to reach a broader audience.</li>
                                    <li className='mb-2'><b>4. Performance Optimization:</b> Ensuring that apps run smoothly, with fast load times and efficient performance on a variety of Android devices.</li>
                                    <li className='mb-2'><b>5. Feature-Rich Functionality:</b> Integrating advanced features such as GPS, push notifications, camera access, and in-app purchases to enhance app functionality.</li>
                                    </ul>

                                </div>


                                {showMore && (
                                    <div className='moretext'>
                                    <ul className="p-0">
                                    <li className='mb-2'><b>6. Security & Compliance:</b> Implementing robust security measures to protect user data and ensure compliance with relevant regulations and standards.</li>
                                    <li className='mb-2'><b>7. API Integration:</b> Connecting your app with third-party services and APIs to extend its capabilities and improve functionality.</li>
                                    <li className='mb-2'><b>8. Testing & Quality Assurance:</b> Conduct thorough testing to identify and resolve any issues before launch, ensuring a high-quality, bug-free app.</li>
                                    <li className='mb-2'><b>9. Maintenance & Support:</b> Providing ongoing maintenance and support to address any issues, update features, and ensure compatibility with new Android versions.</li>
                                    <li className='mb-2'><b>10. Analytics & Reporting:</b> Implementing analytics tools to track app performance, user behaviour, and engagement, providing insights to optimize and enhance the app.</li>
                                    </ul>
                                    <p>These key points highlight the comprehensive approach Grintech takes to deliver high-quality, effective Android app development services.</p>
                                    <p>Grintech’s mobile app developer services combine custom design, robust functionality, and top-notch performance to bring your app vision to life. From intuitive user interfaces to seamless integration and ongoing support, we ensure your app stands out and meets your business goals effectively. Let’s build something amazing together.</p>

                                    </div>
                                )}


                                <Link className="moreless-button" onClick={toggleMoreText}>
                                    {showMore ? 'Read less... ' : 'Read more...'}
                                </Link>


                                <div className='d-flex mt-4'>
                                    <a href='tel: 8264420387' className='btn-bt'> <i className="fa-solid fa-phone"></i> (+1) 833232-6622</a>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 py-5">
                            <Form />
                        </div>
                    </div>
                </div>
                <div className="container d-block d-lg-none">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="passion">

                                <div className=''>
                                <h2>Android App Development Services Provided by Grintech Web Agency:</h2>
                                    <ul className="p-0">
                                    <li className='mb-2'><b>1. Custom App Development:</b> Creating tailored Android apps designed to meet your specific business needs and objectives.</li>
                                    <li className='mb-2'><b>2. User-Friendly Design:</b> Developing intuitive and engaging user interfaces that enhance the user experience and drive user satisfaction.</li>
                                    <li className='mb-2'><b>3. Native & Cross-Platform Solutions:</b> Building both native Android apps and cross-platform apps using frameworks like Flutter or React Native to reach a broader audience.</li>
                                    <li className='mb-2'><b>4. Performance Optimization:</b> Ensuring that apps run smoothly, with fast load times and efficient performance on a variety of Android devices.</li>
                                    <li className='mb-2'><b>5. Feature-Rich Functionality:</b> Integrating advanced features such as GPS, push notifications, camera access, and in-app purchases to enhance app functionality.</li>
                                    </ul>
                                </div>


                                {showMore && (
                                    <div className='moretext'>
                                    <ul className="p-0">
                                    <li className='mb-2'><b>6. Security & Compliance:</b> Implementing robust security measures to protect user data and ensure compliance with relevant regulations and standards.</li>
                                    <li className='mb-2'><b>7. API Integration:</b> Connecting your app with third-party services and APIs to extend its capabilities and improve functionality.</li>
                                    <li className='mb-2'><b>8. Testing & Quality Assurance:</b> Conduct thorough testing to identify and resolve any issues before launch, ensuring a high-quality, bug-free app.</li>
                                    <li className='mb-2'><b>9. Maintenance & Support:</b> Providing ongoing maintenance and support to address any issues, update features, and ensure compatibility with new Android versions.</li>
                                    <li className='mb-2'><b>10. Analytics & Reporting:</b> Implementing analytics tools to track app performance, user behaviour, and engagement, providing insights to optimize and enhance the app.</li>
                                    </ul>
                                    <p>These key points highlight the comprehensive approach Grintech takes to deliver high-quality, effective Android app development services.</p>
                                    <p>Grintech’s mobile app developer services combine custom design, robust functionality, and top-notch performance to bring your app vision to life. From intuitive user interfaces to seamless integration and ongoing support, we ensure your app stands out and meets your business goals effectively. Let’s build something amazing together.</p>

                                    </div>
                                )}


                                <Link className="moreless-button" onClick={toggleMoreText}>
                                    {showMore ? 'Read less... ' : 'Read more...'}
                                </Link>


                                <div className='d-flex mt-4'>
                                    <a href='tel: 833232-6622' className='btn-bt'> <i className="fa-solid fa-phone"></i> (+1) 833232-6622</a>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 py-5">
                            <h1 className='fw-bold'>Android App Development Company</h1>
                            <p>Build Your Vision with Android Development Service by Grintech. We turn your ideas into fully functional, user-friendly Android apps with cutting-edge technology and design. From concept to launch, we ensure your app stands out and performs seamlessly on all Android devices.</p>
                            <Form />
                        </div>
                    </div>
                </div>

            </section>
            <section className="why-us  pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pe-2 pe-md-5 blueback">
                            <div className="frame1">
                                <div className="inner-head pb-2">
                                    <h2>Why Hire Grintech Experts for Android Development</h2>
                                </div>
                                <p>Hire dedicated Android Developer on a Full-time, Hourly, or Fixed Project Cost basis & save up to 25% on development cost</p>
                            </div>

                            <div className="frame1 pt-5">
                                <div className="inner-head pb-2">
                                    <h2>Speed up your advancement with Grintech Experts:</h2>
                                </div>
                                <ul className="ps-3">
                                    <li>Fast hiring within 24 hours</li>
                                    <li>Simple & Transparent Pricing</li>
                                    <li>Fully Signed NDA & Code Security</li>
                                    <li>Regular Reporting & Transparent Communication</li>
                                    <li>Easy Exit Policy</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="why-frame">
                                <ul className="list-unstyled">
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={timeicon} alt="flexible timing" /></div>
                                        <div className="icon-text">
                                            <h5>Flexible Timing</h5>
                                            <p>Grintech also offers flexible timing to accommodate your specific needs. Whether you require assistance during specific hours of the day or need support with managing projects across different time zones, Grintech can tailor their services to meet your scheduling preferences. </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={teamicon} alt="dedicated team" /></div>
                                        <div className="icon-text">
                                            <h5>Dedicated Team</h5>
                                            <p>Grintech dedicated team plays a crucial role in project management, providing the expertise, collaboration, and focus needed to achieve project goals efficiently.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={focusicon} alt="client focus" /></div>
                                        <div className="icon-text">
                                            <h5>Client Focus</h5>
                                            <p>Maintaining a client focus is paramount in any business or project. By prioritizing the needs, preferences, and satisfaction of clients, organizations can build strong relationships, foster loyalty, and drive sustainable growth.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={trusticon} alt="trusted quality" /></div>
                                        <div className="icon-text">
                                            <h5>Trusted Quality</h5>
                                            <p>Trusted quality is a crucial factor for businesses aiming to establish a solid reputation and gain customer trust. It refers to consistently delivering products, services, and experiences that meet or exceed customer expectations, while also maintaining a high standard of reliability, durability, and performance. </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='portfolio'>
                <div className='container'> <h3>Our Portfolio</h3>
                    <h6> Web Development</h6>
                    <Client2 sites={sites} />
                    <Link to="/our-works" style={{ color: "#014072" }}>View all <span className="new-get"><Icon icon="bi:arrow-right" /></span></Link>

                    <div className='mt-5'>
                        <h6> Mobile Development</h6>
                        <Client3 apps={apps} />
                        <Link to="/our-works" style={{ color: "#014072" }}>View all <span className="new-get"><Icon icon="bi:arrow-right" /></span></Link>
                    </div>

                </div>

            </section>
            <ClientReview />
            <Footer />
        </>

    )
}

export default Android
