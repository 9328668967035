import React from 'react'
import { Link } from 'react-router-dom'
import Header from './Header/Header'
import Footer from './Footer/Footer'

const PageNotFound = () => {
  return (
    <>
    <Header />
      <section className="page_404 my-5">
    <div className="row">
      <div className="col-sm-12 ">
        <div className="col-sm-10 col-md-12 mx-auto col-sm-offset-1  text-center">
          <div className="four_zero_four_bg">
          

          </div>

          <div className="contant_box_404 px-3">
            <h1 className="text-center fw-bold ">404</h1>
            <h2 className="fw-bold">
            The page you are looking for is  not available!
            </h2>

           

            <Link to="/" className="link_404">Go to Home</Link>
          </div>
        </div>
      </div>
    </div>
      </section>

      <Footer />
    </>
  )
}

export default PageNotFound